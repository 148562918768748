import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  id: null,
  isCartOpen: false,
  isLoading: false,
  cart: JSON.parse(localStorage.getItem('cartItems')) || [],
  items: [],
  total: parseFloat(localStorage.getItem('cartTotal')) || 0,
};

export const cartSlice = createSlice({
  name: 'cart',
  initialState,
  reducers: {
    setItems: (state, action) => {
      state.items = action.payload;
    },

    removeItemFromShop: (state, action) => {
      state.items = state.items.filter((item) => item.id !== action.payload.id);
    },

    addToCart: (state, action) => {
      const data = [...state.cart];
      const test = action.payload.item;

      function getExact(test, data) {
        return data.some((obj) => {
          return obj.id === test.id;
        });
      }

      if (getExact(test, data) === true) {
        state.cart = state.cart.map((item) => {
          if (item.id === action.payload.item.id) {
            item.count += test.count;
          }
          return item;
        });
      } else {
        state.cart = [...state.cart, action.payload.item];
      }
      state.total = state.total + parseFloat(test.price) * test.count;
    },

    removeFromCart: (state, action) => {
      //the little "x" to the right of each item in cart
      const epsilon = 0.00001;
      const data = [...state.cart];

      let elementToRemove = data.filter(
        (item) => item.id === action.payload.id
      );
      const item = elementToRemove[0];
      const dollarsToRemove = parseFloat(item.price) * item.count;
      state.total = state.total - dollarsToRemove;
      if (Math.abs(state.total) < epsilon) {
        state.total = 0;
      }
      state.cart = data.filter((item) => item.id !== action.payload.id);
    },

    increaseCount: (state, action) => {
      const amountToAdd = state.cart.filter(
        (item) => item.id === action.payload.id
      );
      state.total = state.total + parseFloat(amountToAdd[0].price);
      state.cart = state.cart.map((item) => {
        if (item.id === action.payload.id) {
          item.count++;
        }
        return item;
      });
    },

    decreaseCount: (state, action) => {
      const epsilon = 0.00001;
      const data = [...state.cart];
      const amountToSubtract = state.cart.filter(
        (item) => item.id === action.payload.id
      );
      state.total = state.total - parseFloat(amountToSubtract[0].price);
      if (Math.abs(state.total) < epsilon) {
        state.total = 0;
      }
      state.cart = data.map((item) => {
        if (item.id === action.payload.id) {
          item.count--;
        }
        return item;
      });
    },

    clearCart: (state) => {
      state.cart = [];
      state.total = 0;
      state.id = null;
      localStorage.removeItem('cartItems');
      localStorage.removeItem('cartTotal');
    },

    setIsCartOpen: (state, action) => {
      state.isCartOpen = action.payload;
    },

    setIsLoading: (state) => {
      state.isLoading = !state.isLoading;
    },

    adjustTotal: (state, action) => {
      state.total = action.payload;
    },

    setID: (state, action) => {
      state.id = action.payload;
    },
  },
});

export const {
  setItems,
  removeItemFromShop,
  addToCart,
  removeFromCart,
  increaseCount,
  decreaseCount,
  clearCart,
  setIsCartOpen,
  setIsLoading,
  adjustTotal,
  setID,
} = cartSlice.actions;

export default cartSlice.reducer;
export const cartState = (state) => state.cart;
