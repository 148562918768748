import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  home: 0,
  dashboard: 0,
  checkout: 0,
};

export const pageVisitedSlice = createSlice({
  name: 'pageVisited',
  initialState,
  reducers: {
    visited: (state, action) => {
      return {
        ...state,
        [action.payload.name]: action.payload.value,
      };
    },
    reset: () => {
      return initialState;
    },
  },
});

export const { visited, reset } = pageVisitedSlice.actions;
export default pageVisitedSlice.reducer;
export const isPageVisited = (state) => state.pageVisited;
