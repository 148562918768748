import { createSlice } from '@reduxjs/toolkit';

const loadUserFromStorage = () => {
  const user = localStorage.getItem('user');
  if (user) {
    return JSON.parse(user);
  }
  return null;
};

const initialState = {
  isLoggedIn: false,
  email: '',
  user: loadUserFromStorage(),
};

export const isLoggedInSlice = createSlice({
  name: 'isLoggedIn',
  initialState,
  reducers: {
    login: (state, action) => {
      const cognitoUser = action.payload;
      state.user = cognitoUser;
      localStorage.setItem('user', JSON.stringify(action.payload));
    },
    logout: (state) => {
      state.user = null;
      localStorage.removeItem('user');
    },
    update: (state, action) => {
      return {
        ...state,
        [action.payload.name]: action.payload.value,
      };
    },
    reset: () => {
      return initialState;
    },
  },
});

export const { update, reset, login, logout } = isLoggedInSlice.actions;
export default isLoggedInSlice.reducer;
export const isUserLoggedIn = (state) => state.isLogged;
