import { createSlice } from '@reduxjs/toolkit';

const address = {
  line1: '',
  line2: '',
  city: '',
  state: '',
  postal_code: '',
  country: '',
};

const initialState = {
  clientSecret: null,
  shippoOrderID: '',
  invoiceID: null,
  stripeCustomerID: null,
  customer: {
    name: '',
    email: '',
    address: address,
  },
  shipment: {
    // a combined object with data derived from initialShippingData input (determineParcel) and chosen rate
    type: '',
    cost: '',
    parcel: '',
    carrier: '',
    serviceLevel: '',
    defaultPackage: true,
  },
  tax: {
    salesTax: 0,
    shippingTax: 0,
  },
  ratesList: [],
  selectedRate: '', // object_id of rate chosen
  initialShippingData: [], // data collected initially on checkout page load based on cart
  total: '', // String total of items in cart only, does not include tax or shipping
};

export const checkoutSlice = createSlice({
  name: 'checkout',
  initialState,
  reducers: {
    setClientSecret: (state, action) => {
      state.clientSecret = action.payload;
    },
    setCheckoutState: (state, action) => {
      return { ...state, ...action.payload };
    },
    setCustomer: (state, action) => {
      state.customer.name = action.payload.name;
      state.customer.address = action.payload.address;
    },
    setEmail: (state, action) => {
      state.customer.email = action.payload;
    },
    setShipment: (state, action) => {
      state.shipment = { ...state.shipment, ...action.payload };
    },
    modifyAddress: (state) => {
      return {
        ...initialState,
        clientSecret: state.clientSecret,
        initialShippingData: state.initialShippingData,
        total: state.total,
        stripeCustomerID: state.stripeCustomerID,
        customer: state.customer,
      };
    },
    setTax: (state, action) => {
      state.tax = { ...state.tax, ...action.payload };
    },
    reset: () => {
      return initialState;
    },
  },
});

export const {
  setClientSecret,
  setCheckoutState,
  setCustomer,
  setEmail,
  setShipment,
  modifyAddress,
  setTax,
  reset,
} = checkoutSlice.actions;
export default checkoutSlice.reducer;
export const checkout = (state) => state.checkout;
