import React from 'react';
import { SwipeableDrawer } from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';
import CloseIcon from '@mui/icons-material/Close';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import { useNavigate } from 'react-router-dom';
import {
  cartState,
  decreaseCount,
  increaseCount,
  removeFromCart,
  setIsCartOpen,
} from '../redux/sliceCart';
import {
  ActionContainer,
  ActionInnerContainer,
  AmountContainer,
  AmountInnerContainer,
  CheckoutButton,
  Header,
  Image,
  ImageContainer,
  ItemContainer,
  ItemTitleContainer,
  MainCartContainer,
  ModalContent,
  NoItemsContainer,
} from '../styles/stylesCartMenu';
import { Box, Divider, IconButton, Typography } from '@mui/material';

const CartMenu = () => {
  const dispatch = useDispatch();
  const thisCart = useSelector(cartState);
  const cart = thisCart.cart;
  const isCartOpen = thisCart.isCartOpen;
  const totalPrice = thisCart.total;
  //const isAuth = useSelector(isUserLoggedIn);
  //const email = isAuth.user;
  const navigate = useNavigate();

  const handleCheckoutButton = async () => {
    dispatch(setIsCartOpen(false));
    navigate('/Checkout/payment');
  };

  return (
    <>
      <SwipeableDrawer
        anchor="right"
        open={isCartOpen}
        onClose={() => dispatch(setIsCartOpen(false))}
        onOpen={() => dispatch(setIsCartOpen(true))}
        aria-label="Shopping Cart"
      >
        <MainCartContainer>
          <ModalContent>
            <Header>
              <Typography variant="h4" fontFamily={`'Grand Hotel', cursive`}>
                Shopping Bag ({cart.length})
              </Typography>
              <IconButton
                onClick={() => dispatch(setIsCartOpen(false))}
                aria-label="Close cart"
              >
                <CloseIcon />
              </IconButton>
            </Header>
            {cart.length === 0 ? (
              <NoItemsContainer>
                <Typography>There are no items in the cart.</Typography>
              </NoItemsContainer>
            ) : (
              <>
                <Box>
                  {cart.map((item) => (
                    <div key={`${item.name}-${item.id}`}>
                      <ItemContainer>
                        <ImageContainer>
                          <Image alt={item?.name} src={item.image} />
                        </ImageContainer>
                        <Box flex="1 1 60%" ml={1}>
                          <ItemTitleContainer>
                            <Typography fontWeight="bold">
                              {item.name}
                            </Typography>
                            <IconButton
                              onClick={() =>
                                dispatch(removeFromCart({ id: item.id }))
                              }
                              aria-label={`Remove ${item.name} from cart`}
                            >
                              <CloseIcon />
                            </IconButton>
                          </ItemTitleContainer>
                          <Typography>{item.shortDescription}</Typography>
                          <AmountContainer>
                            <AmountInnerContainer>
                              <IconButton
                                onClick={() =>
                                  dispatch(
                                    item.count > 1
                                      ? decreaseCount({ id: item.id })
                                      : removeFromCart({ id: item.id })
                                  )
                                }
                                aria-label={`Decrease quantity of ${item.name}`}
                              >
                                <RemoveIcon />
                              </IconButton>
                              <Typography>{item.count}</Typography>
                              <IconButton
                                onClick={() =>
                                  dispatch(increaseCount({ id: item.id }))
                                }
                                aria-label={`Increase quantity of ${item.name}`}
                              >
                                <AddIcon />
                              </IconButton>
                            </AmountInnerContainer>
                            <Typography fontWeight="bold">
                              ${item.price}
                            </Typography>
                          </AmountContainer>
                        </Box>
                      </ItemContainer>
                      <Divider />
                    </div>
                  ))}
                </Box>
                <ActionContainer>
                  <ActionInnerContainer>
                    <Typography fontWeight="bold">SUBTOTAL</Typography>
                    <Typography fontWeight="bold">
                      ${totalPrice.toFixed(2)}
                    </Typography>
                  </ActionInnerContainer>
                  <CheckoutButton
                    onClick={handleCheckoutButton}
                    aria-label="Proceed to checkout and complete your purchase"
                  >
                    CHECKOUT
                  </CheckoutButton>
                </ActionContainer>
              </>
            )}
          </ModalContent>
        </MainCartContainer>
      </SwipeableDrawer>
    </>
  );
};

export default CartMenu;

/*

  const handleCheckoutButton = async () => {
    const cartCopy = cart.map((item) => ({ ...item }));
    dispatch(setIsLoading({}));

    try {
      const sessionID = await mainCheckoutSequence(cartCopy, totalPrice, email);
      if (sessionID) {
        await redirect(sessionID);
      } else {
        alert('Checkout session could not be created. Please try again.');
        dispatch(setIsLoading({})); // Reset loading state if sessionID is not created
      }
    } catch (error) {
      console.error('Error during checkout:', error);
      alert('An error occurred during the checkout process. Please try again.');
      dispatch(setIsLoading({})); // Reset loading state if an error occurs
    }
  };

  const redirect = async (sessionID) => {
    try {
      const stripePromise = loadStripe(
        `${process.env.REACT_APP_STRIPE_PUBLIC_KEY}`
      );
      const stripe = await stripePromise;

      dispatch(setIsLoading({}));
      await stripe.redirectToCheckout({
        sessionId: sessionID,
      });
    } catch (error) {
      console.error('Error during redirect to checkout:', error);
      alert(
        'An error occurred while redirecting to the checkout page. Please try again.'
      );
      dispatch(setIsLoading({}));
    }
  };

*/
