import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  id: '',
  name: '',
  price: '',
  image: null,
  shortDescription: '',
  description: '',
  featured: false,
  beans: false,
  fragile: false,
  version: '',
  weight: '',
  weightUnit: '',
};

export const adminAddProductSlice = createSlice({
  name: 'adminAddProduct',
  initialState,
  reducers: {
    update: (state, action) => {
      return {
        ...state,
        [action.payload.name]: action.payload.value,
      };
    },
    updateAll: (state, action) => {
      return {
        ...state,
        id: action.payload.id,
        name: action.payload.name,
        price: action.payload.price,
        image: action.payload.image,
        shortDescription: action.payload.shortDescription,
        description: action.payload.description,
        featured: action.payload.featured,
        version: action.payload.version,
        weightUnit: action.payload.weightUnit,
        weight: action.payload.weight,
      };
    },
    reset: () => {
      return initialState;
    },
  },
});

export const { update, updateAll, reset } = adminAddProductSlice.actions;
export default adminAddProductSlice.reducer;
export const addProduct = (state) => state.adminAdd;
