import React from 'react';
import { Backdrop, CircularProgress } from '@mui/material';
import { useSelector } from 'react-redux';
import { cartState } from '../redux/sliceCart';

const GlobalCheckoutLoading = () => {
  const thisCart = useSelector(cartState);
  const loading = thisCart.isLoading;
  //console.log("rendering: ", loading)
  return (
    <Backdrop
      sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 3 }}
      open={loading}
    >
      <CircularProgress color="inherit" />
    </Backdrop>
  );
};

export default GlobalCheckoutLoading;
