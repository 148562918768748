import { Button } from '@mui/material';
import styled from 'styled-components';

export const FlexBox = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
export const NoItemsContainer = styled.div`
  display: flex;
  justify-content: center;
  margin: 30px 0 0 0;
`;
export const MainCartContainer = styled.div`
  background-color: #fff9d4;
  max-width: 400px;
  height: 100%;

  @media screen and (max-width: 768px) {
    max-width: 300px;
  }
`;
export const ModalContent = styled.div`
  padding: 15px;
  overflow: auto;
  height: 100%;
`;
export const Header = styled(FlexBox)`
  margin-bottom: 15px;
  min-width: 280px;
`;
export const ItemContainer = styled(FlexBox)`
  padding: 15px 0;
`;
export const ImageContainer = styled.div`
  flex: 1 1 40%;
`;
export const Image = styled.img`
  width: 123px;
  height: 164px;
  border: 2px solid black;

  @media screen and (max-width: 768px) {
    width: 100px;
    height: 140px;
  }
`;
export const ItemTitleContainer = styled(FlexBox)`
  margin-bottom: 5px;
`;
export const AmountContainer = styled(FlexBox)`
  margin: 25px 0 5px;
`;
export const AmountInnerContainer = styled.div`
  display: flex;
  align-items: center;
  border: 1.5px solid lightgray;
`;
export const ActionContainer = styled.div`
  margin: 20px 0;
`;
export const ActionInnerContainer = styled(FlexBox)`
  margin: 20px 0;
`;
export const CheckoutButton = styled(Button)`
  width: 100% !important;
  background-color: #744500 !important;
  color: #fff9d4 !important;
`;
