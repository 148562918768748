import './App.css';
import { Provider } from 'react-redux';
import { ThemeProvider } from '@mui/material';
import { theme } from './styles/muiStyles';
import { BrowserRouter as Router } from 'react-router-dom';
import store from './redux/store';
import CartMenu from './components/cartMenu';
import AnimatedRoutes from './components/animatedRoutes';
import GlobalCheckoutLoading from './components/globalCheckoutLoading';

function App() {
  return (
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <Router>
          <AnimatedRoutes />
          <GlobalCheckoutLoading />
          <CartMenu />
        </Router>
      </ThemeProvider>
    </Provider>
  );
}

export default App;

/*




*/
