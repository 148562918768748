import React, { lazy, Suspense } from 'react';
import { Route, Routes, useLocation, Outlet } from 'react-router-dom';
import { AnimatePresence } from 'framer-motion';
import CircularProgress from '@mui/material/CircularProgress';
import { Box } from '@mui/material';

const Home = lazy(() => import('../features/home/pages/home'));
const Compare = lazy(() => import('../pages/comparison'));
const About = lazy(() => import('../pages/about'));
const Gallery = lazy(() => import('../features/gallery/pages/front/gallery'));
const Confirmation = lazy(
  () => import('../features/checkout/pages/confirmation/confirmation')
);
const AccountLogin = lazy(
  () => import('../features/accountLogin/pages/accountLogin/accountLogin')
);
const WelcomePage = lazy(
  () => import('../features/accountLogin/pages/welcomePage/welcomePage')
);
const AccountDashboard = lazy(
  () => import('../features/accountDashboard/pages/accountDashboard')
);
const HistoryGallery2 = lazy(
  () => import('../features/gallery/pages/history/historyGallery2')
);
const FlowersGallery = lazy(
  () => import('../features/gallery/pages/flowers/flowersGallery')
);
const Contact = lazy(() => import('../pages/contact'));
const ProductDetails = lazy(() => import('../pages/productDetails'));
const LoginLanding = lazy(
  () => import('../features/accountLogin/pages/loginLanding/loginLanding')
);
const ControlBoard = lazy(() => import('../features/admin/pages/controlBoard'));
const ShippingPolicy = lazy(() => import('../pages/shippingPolicy'));
const ReturnPolicy = lazy(() => import('../pages/returnPolicy'));
const Extract = lazy(() => import('../pages/extract2'));
const CustomCheckout = lazy(
  () => import('../features/checkout/pages/customCheckout/customCheckout')
);

const Circular = () => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        zIndex: 40,
        height: '100vh',
      }}
    >
      <CircularProgress color="secondary" />
    </Box>
  );
};

const SuspenseLayout = () => (
  <Suspense fallback={<Circular />}>
    <Outlet />
  </Suspense>
);

const AnimatedRoutes = () => {
  const location = useLocation();

  return (
    <AnimatePresence mode="wait">
      <Routes key={location.pathname} location={location}>
        <Route element={<SuspenseLayout />}>
          <Route path="/" element={<Home />} />
          <Route path="/About" element={<About />} />
          <Route path="/Gallery" element={<Gallery />} exact />
          <Route path="/Contact" element={<Contact />} />
          <Route path="/Compare" element={<Compare />} />
          <Route path="/Gallery/history2" element={<HistoryGallery2 />} />
          <Route path="/Gallery/flowers" element={<FlowersGallery />} />
          <Route path="/AdminControlBoard" element={<ControlBoard />} exact />
          <Route path="item/:itemId" element={<ProductDetails />} exact />
          <Route path="/Checkout/success" element={<Confirmation />} />
          <Route path="/Checkout/payment" element={<CustomCheckout />} />
          <Route path="/AccountLogin" element={<AccountLogin />} />
          <Route path="/AccountLogin/success" element={<LoginLanding />} />
          <Route path="/WelcomePage" element={<WelcomePage />} />
          <Route path="/AccountDashboard" element={<AccountDashboard />} />
          <Route path="/ShippingPolicy" element={<ShippingPolicy />} />
          <Route path="/ReturnPolicy" element={<ReturnPolicy />} />
          <Route path="/VanillaExtract" element={<Extract />} />
        </Route>
      </Routes>
    </AnimatePresence>
  );
};

export default AnimatedRoutes;

/*



*/
