import { createTheme } from '@mui/material';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import { TableRow, Paper } from '@mui/material';
import { styled } from '@mui/material/styles';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';

export const theme = createTheme({
  typography: {
    fontFamily: 'Reem Kufi Fun',
  },
});

export const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: '#FFF9d4',
    color: theme.palette.common.black,
    fontSize: 14,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 12,
  },
}));

export const StyledTableRow = styled(TableRow)(({ theme }) => ({
  backgroundColor: '#FFF9d4',
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

export const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#FFF9d4',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));

export const StyledTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#FFF9d4',
    color: 'rgba(0, 0, 0, 0.87)',
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
    border: '1px solid #dadde9',
    boxShadow: '0px 0px 21px 4px rgba(0,0,0,0.53)',
  },
}));

export const TransparentTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    borderBottom: '1px solid #FFF9d4',
    backgroundColor: 'transparent',
    color: '#FFF9d4',
  },
  [`&.${tableCellClasses.body}`]: {
    borderBottom: '1px solid #FFF9d4',
    fontSize: 14,
    color: '#FFF9d4',
  },
}));

export const TransparentTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: 'transparent',
    color: '#FFF9d4',
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));
