import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Amplify } from 'aws-amplify';
import amplifyconfig from './amplifyconfiguration.json';
import './index.css';
//import config from './aws-exports';

Amplify.configure(amplifyconfig);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<App />);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
if (process.env.NODE_ENV === 'development') {
  reportWebVitals(console.log);
}

/*

  <React.StrictMode>
    <App/>
  </React.StrictMode>


  <React.StrictMode>
    <AmplifyProvider>
      <App/>
    </AmplifyProvider>
  </React.StrictMode>

*/
