import { configureStore } from '@reduxjs/toolkit';
import adminAddReducer from './sliceAdminAdd';
import cartReducer from './sliceCart';
import loggedInReducer from './sliceLoggedIn';
import pageVisitedReducer from './slicePageVisited';
import checkoutReducer from './sliceCheckout';
import logger from 'redux-logger';

const saveCartToLocalStorage = (store) => (next) => (action) => {
  const result = next(action);
  if (action.type.startsWith('cart/')) {
    const cartState = store.getState().cart;
    localStorage.setItem('cartItems', JSON.stringify(cartState.cart));
    localStorage.setItem('cartTotal', cartState.total.toString());
  }
  return result;
};

const isDev = process.env.NODE_ENV === 'development';

const middleware = (getDefaultMiddleware) => {
  const middlewares = getDefaultMiddleware().concat(saveCartToLocalStorage);
  if (isDev) {
    middlewares.push(logger);
  }
  return middlewares;
};

export default configureStore({
  reducer: {
    adminAdd: adminAddReducer,
    cart: cartReducer,
    isLogged: loggedInReducer,
    pageVisited: pageVisitedReducer,
    checkout: checkoutReducer,
  },
  middleware,
});
